import { BusinessLogger } from 'feature-business-logger'
import { WixBiSession, ILanguage } from '@wix/thunderbolt-symbols'
import { ViewModeProp } from './types'

export const BIEvents = (
	businessLogger: BusinessLogger,
	biSession: WixBiSession,
	viewMode: ViewModeProp,
	language: ILanguage
) => {
	const generateParams = (context?: string, layout?: string) => ({
		biToken: biSession.msId,
		context,
		ts: getCurrentTimeStamp(biSession),
		viewmode: viewMode,
		visitor_id: biSession.visitorId,
		site_member_id: biSession.siteMemberId,
		vsi: biSession.viewerSessionId,
		site_settings_lng: language.siteLanguage,
		browser_lng: language.userLanguage,
		lng_mismatch: language.siteLanguage !== language.userLanguage,
		layout,
	})
	return {
		closingDialog: (context: string, layout = 'fullscreen') => {
			businessLogger.logger.log(
				{
					src: 5,
					evid: 602,
					...generateParams(context, layout),
				},
				{ endpoint: 'site-members' }
			)
		},
		emailAuthSubmitClicked: (context: string, layout: string) => {
			businessLogger.logger.log(
				{ src: 5, evid: 603, ...generateParams(context, layout) },
				{ endpoint: 'site-members' }
			)
		},
		siteMembersFeatureLoaded: () => {
			businessLogger.logger.log({ src: 5, evid: 698, ...generateParams() }, { endpoint: 'site-members' })
		},
		siteMembersSdkFeatureLoaded: () => {
			businessLogger.logger.log({ src: 5, evid: 699, ...generateParams() }, { endpoint: 'site-members' })
		},
	}
}

const getCurrentTimeStamp = (biSession: WixBiSession) => {
	const start = biSession.initialTimestamp || 0
	return Date.now() - start
}
